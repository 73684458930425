import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Router, Route, IndexRoute, browserHistory, Redirect } from 'react-router';
import { Switch } from 'react-router-dom';
import { syncHistoryWithStore, routerReducer, routerMiddleware, push } from 'react-router-redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import App from './components/App';
import Home from './components/home/Home';
import Symps from './components/Symps/Symps';
import SympsISAI from './components/Symps/ind_symp/ISAI';
import SympsISBD from './components/Symps/ind_symp/ISBD';
import SympsISCB from './components/Symps/ind_symp/ISCB';
import SympsISCC from './components/Symps/ind_symp/ISCC';
import SympsISCI from './components/Symps/ind_symp/ISCI';
import SympsISCS from './components/Symps/ind_symp/ISCS';
import SympsISCW from './components/Symps/ind_symp/ISCW';
import SympsISED from './components/Symps/ind_symp/ISED';
import SympsISHI from './components/Symps/ind_symp/ISHI';
import SympsISMC from './components/Symps/ind_symp/ISMC';
import SympsISNA from './components/Symps/ind_symp/ISNA';
import SympsISOT from './components/Symps/ind_symp/ISOT';
import SympsISPC from './components/Symps/ind_symp/ISPC';
import SympsISPD from './components/Symps/ind_symp/ISPD';
import SympsISSC from './components/Symps/ind_symp/ISSC';
import SympsISSE from './components/Symps/ind_symp/ISSE';
import Demo from './components/Demo';
import Pub from './components/Publisher';
import Schedules from './components/Schedules';
import PaperS from './components/Paper_sub';
import PapC from './components/Paper_Cat';
import Contact from './components/Contact'
import Scope from './components/Scope';
import Review from './components/Review'
import Deadlines from './components/Deadlines'
import Org from './components/Org'
import Venue from './components/Venue'
import Reg from './components/Reg'
import Authors from './components/Authors'
import PapC_2 from './components/Paper_Cat_2';
import PapC_4 from './components/Paper_Cat_4';
import PapC_6 from './components/Paper_Cat_6';
import PresMs from './components/Pres_Modes';
import Jobs from './components/Jobs';
import Reg_Form from './components/Reg_Pages/Reg_Form'
import Reg_Form2 from './components/Reg_Pages/Reg_Form2'
import Reg_start from './components/Reg_Pages/Reg_Start'
import Reg_Fee_C from './components/Reg_Pages/Reg_Fee_C'
import Reg_Fee_C2 from './components/Reg_Pages/Reg_Fee_C2'
import Reg_Card from './components/Reg_Pages/Reg_Card'
import Reg_Card2 from './components/Reg_Pages/Reg_Card2'
import Reg_Card_Arabnia from './components/Reg_Pages/Reg_Card_Arabnia'
import Reg_done from './components/Reg_Pages/Reg_Done'
import Photos from './components/Photos'

import Visa_start from './components/Visa/Visa_Start'
import Visa_Form from './components/Visa/Visa_Form'
import Visa_done from './components/Visa/Visa_Done'
import Reg_done_cust from './components/Reg_Pages/Reg_Done_Cust'
import Reg_Card_Cust from './components/Reg_Pages/Reg_Card_Cust'

import Keynotes from './components/Keynotes'
import Keynote1 from './components/keynote1'
// import Keynote2 from './components/keynote2'
import Keynote3 from './components/keynote3'
import Keynote4 from './components/keynote4'

import reducers from './reducers';
import './components/bundle.scss';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { PersistGate } from 'redux-persist/integration/react';

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['routing', 'Spinner_ActionReducer', 'alertReducer']
}

const persistedReducer = persistReducer(persistConfig, reducers)
const routermiddleware = routerMiddleware(browserHistory)

const store = createStore(persistedReducer, composeWithDevTools(
  applyMiddleware(thunk, routermiddleware),
));

const history = syncHistoryWithStore(browserHistory, store)
let persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Switch>
          <Route path="/" component={App}>
            <IndexRoute component={Home} />
            <Route path="/csci2024/" component={Home} />
            <Route path="/csci2024/RTAI" component={SympsISAI} />
            <Route path="/csci2024/RTBD" component={SympsISBD} />
            <Route path="/csci2024/RTCB" component={SympsISCB} />
            <Route path="/csci2024/RTCC" component={SympsISCC} />
            <Route path="/csci2024/RTCI" component={SympsISCI} />
            <Route path="/csci2024/RTCS" component={SympsISCS} />
            <Route path="/csci2024/RTCW" component={SympsISCW} />
            <Route path="/csci2024/RTED" component={SympsISED} />
            <Route path="/csci2024/RTHI" component={SympsISHI} />
            <Route path="/csci2024/RTMC" component={SympsISMC} />
            <Route path="/csci2024/RTNA" component={SympsISNA} />
            <Route path="/csci2024/RTOT" component={SympsISOT} />
            <Route path="/csci2024/RTPC" component={SympsISPC} />
            <Route path="/csci2024/RTPD" component={SympsISPD} />
            <Route path="/csci2024/RTSC" component={SympsISSC} />
            <Route path="/csci2024/RTSE" component={SympsISSE} />
            <Route path="/csci2024/research_tracks" component={Symps} />
            <Route path="/csci2024/demography_history" component={Demo} />
            <Route path="/csci2024/publisher" component={Pub} />
            <Route path="/csci2024/paper_submission" component={PaperS} />
            <Route path="/csci2024/paper_categories" component={PapC} />
            <Route path="/csci2024/contact" component={Contact} />
            <Route path="/csci2024/topics" component={Scope} />
            <Route path="/csci2024/paper_review_pocess" component={Review} />
            <Route path="/csci2024/deadlines" component={Deadlines} />
            <Route path="/csci2024/committees" component={Org} />
            <Route path="/csci2024/venue" component={Venue} />
            <Route path="/csci2024/registration" component={Reg} />
            <Route path="/csci2024/registration_start" component={Reg_start} />
            <Route path="/csci2024/registration_form" component={Reg_Form} />
            <Route path="/csci2024/registration_form_add" component={Reg_Form2} />
            <Route path="/csci2024/registration_fee_calc" component={Reg_Fee_C} />
            <Route path="/csci2024/registration_fee_calc_add" component={Reg_Fee_C2} />
            <Route path="/csci2024/registration_card" component={Reg_Card} />
            <Route path="/csci2024/registration_card_add" component={Reg_Card2} />
            <Route path="/csci2024/registration_success" component={Reg_done} />
            <Route path="/csci2024/authors" component={Authors} />
            <Route path="/csci2024/paper_category_rrp" component={PapC_6} />
            <Route path="/csci2024/paper_category_srp" component={PapC_4} />
            <Route path="/csci2024/paper_category_pst" component={PapC_2} />
            <Route path="/csci2024/presentation_modes" component={PresMs} />
            <Route path="/csci2024/career_fair" component={Jobs} />
            <Route path="/csci2024/schedules" component={Schedules} />
            <Route path="/csci2024/gallery" component={Photos} />
            
            <Route path="/csci2024/visa/start" component={Visa_start} />
            <Route path="/csci2024/visa/form" component={Visa_Form} />
            <Route path="/csci2024/visa/done" component={Visa_done} />
            <Route path="/csci2024/custom_payment/:url_id" component={Reg_Card_Cust} />
            <Route path="/csci2024/custom_pyment_success" component={Reg_done_cust} />

            <Route path="/csci2024/keynotes" component={Keynotes} />
            <Route path="/csci2024/keynotes/murray" component={Keynote1} />
            {/* <Route path="/csci2024/keynotes/bhargava" component={Keynote2} /> */}
            <Route path="/csci2024/keynotes/gharehmohammadi" component={Keynote3} />
            <Route path="/csci2024/keynotes/amirian" component={Keynote4} />


            {/* <Route path="/csci2024/registration_card_arabnia" component={Reg_Card_Arabnia} /> */}

            <Route path='*' component={Home} />
          </Route>
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
  , document.getElementById('react-root'));
